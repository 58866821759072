import * as Ariakit from "@ariakit/react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { ChevronDown } from "lucide-react";
import { matchSorter } from "match-sorter";
import {
  type FunctionComponent,
  type SVGProps,
  startTransition,
  useMemo,
  useRef,
  useState,
} from "react";
import { tv } from "tailwind-variants";

export interface ListItem {
  value: string;
  label: string;
}

interface ComboboxProps {
  listItems: ListItem[];
  label: string;
  LabelIcon: FunctionComponent<SVGProps<SVGSVGElement>>;
  value: string;
  onChange: (value: string) => void;
  color?: "primary" | "secondary";
  size?: "sm";
  disabled?: boolean;
  emptyText?: string;
}

const comboboxClassName = tv({
  slots: {
    wrapper: "flex flex-col gap-1 md:w-full",
    labelSlot: "font-medium text-sm",
    select:
      "w-full  border rounded-lg border-neutral-200 h-10 px-4 py-2 hover:bg-white md:h-14 bg-white text-neutral-600 justify-start inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-neutral-500 transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
    labelIcon: "h-5 w-5 min-h-5 mr-2",
    selectValue: "flex-1 text-start overflow-clip",
    selectIcon: "ml-2 h-4 w-4 shrink-0 opacity-50",
    popover:
      "z-50 flex flex-col overflow-auto h-[300px] overscroll-contain rounded-lg border border-solid border-neutral-200 bg-neutral-50 p-2 pt-0",
    popoverSearch:
      "sticky top-0 mb-2 w-full bg-inherit pt-2 z-50 h-10 w-full rounded text-neutral-500 bg-neutral-100 px-4 text-base leading-6 text-black hover:bg-neutral-200 focus-visible:outline-2 focus-visible:outline-offset-[-1px] outline-primary-500",
    popoverList: "h-[${virtualizer.getTotalSize()}px] w-full relative",
    popoverItem:
      "text-nowrap h-9 absolute flex cursor-default scroll-m-2 items-center gap-1 rounded-md p-1 outline-none w-full scroll-mt-0.5 text-neutral-950 data-[active-item]:bg-primary-200",
    disabledWrapper: "opacity-50 pointer-events-none",
  },
  variants: {
    color: {
      primary: {
        labelSlot: "text-neutral-50",
      },
      secondary: {
        labelSlot: "text-primary-600",
      },
    },
    size: {
      sm: {
        wrapper: "flex flex-col gap-1",
      },
    },
  },
  defaultVariants: {
    color: "primary",
  },
});

export function Combobox({
  listItems,
  label,
  LabelIcon,
  value,
  onChange,
  color,
  size,
  disabled = false,
  emptyText,
}: ComboboxProps): JSX.Element {
  const [searchValue, setSearchValue] = useState("");
  const { wrapper, labelSlot, select, disabledWrapper } = comboboxClassName({
    color,
    size,
  });
  const matches = useMemo(() => {
    return matchSorter(listItems, searchValue, {
      keys: ["value"],
    });
  }, [listItems, searchValue]);

  const parentRef = useRef<HTMLDivElement>(null);

  const virtualizer = useVirtualizer({
    count: matches.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 36,
    overscan: 10,
  });

  const renderSelectValue = (): string => {
    if (emptyText && !value) {
      return emptyText;
    }
    if (value) {
      return value;
    }
    return label;
  };

  return (
    <div className={`${wrapper()} ${disabled ? disabledWrapper() : ""}`}>
      <Ariakit.ComboboxProvider
        setValue={(v) => {
          startTransition(() => {
            setSearchValue(v);
          });
        }}
      >
        <Ariakit.SelectProvider value={value} setValue={onChange}>
          <Ariakit.SelectLabel className={labelSlot()}>
            {label}
          </Ariakit.SelectLabel>
          <Ariakit.Select className={select()}>
            <LabelIcon
              className={`h-5 w-5 min-h-5 mr-2 ${value ? "text-primary-600" : "text-neutral-500"}`}
            />
            <div
              className={`flex-1 text-start overflow-clip ${value ? "text-neutral-900" : "text-neutral-500"}`}
            >
              {renderSelectValue()}
            </div>
            <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Ariakit.Select>
          <Ariakit.SelectPopover
            gutter={4}
            sameWidth
            ref={parentRef}
            className="z-50 flex flex-col overflow-auto h-[300px] overscroll-contain rounded-lg border border-solid border-neutral-200 bg-neutral-50 p-2 pt-0"
          >
            <div className="sticky top-0 mb-2 w-full bg-inherit pt-2 z-50">
              <Ariakit.Combobox
                className="h-10 w-full rounded text-neutral-500 bg-neutral-100 px-4 text-base leading-6 text-black hover:bg-neutral-200 focus-visible:outline-2 focus-visible:outline-offset-[-1px] outline-primary-500"
                autoSelect
                placeholder="Buscar..."
              />
            </div>
            <Ariakit.ComboboxList
              className={`h-[${virtualizer.getTotalSize()}px] w-full relative`}
            >
              {virtualizer.getVirtualItems().map((v) => (
                <Ariakit.SelectItem
                  style={{
                    transform: `translateY(${v.start}px)`,
                  }}
                  focusOnHover
                  key={v.key}
                  data-index={v.index}
                  ref={virtualizer.measureElement}
                  value={matches[v.index]?.value}
                  className="text-nowrap h-9 absolute flex cursor-default scroll-m-2 items-center gap-1 rounded-md p-1 outline-none w-full scroll-mt-0.5 text-neutral-950  data-[active-item]:bg-primary-200"
                  render={<Ariakit.ComboboxItem />}
                />
              ))}
              {!matches.length && (
                <div className="px-2">0 resultados encontrados</div>
              )}
            </Ariakit.ComboboxList>
          </Ariakit.SelectPopover>
        </Ariakit.SelectProvider>
      </Ariakit.ComboboxProvider>
    </div>
  );
}
