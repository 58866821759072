import { Button } from "@/components/ui/button";
import * as Collapsible from "@radix-ui/react-collapsible";
import { useNavigate } from "@remix-run/react";
import { BookHeartIcon, MapIcon, Search, StethoscopeIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { tv } from "tailwind-variants";
import type { States } from "~/data/data";
import { toUrlFilename } from "~/lib/search-utils";
import { Combobox } from "./combobox";
import { CombinedCombobox } from "./combobox-combined";
import { Wrapper } from "./wrapper";

export interface ListItem {
  value: string;
  label: string;
}

const searchForm = tv({
  slots: {
    searchBtn: "primary px-2",
    base: "",
  },
  variants: {
    color: {
      primary: {
        base: "bg-primary-600",
        searchBtn: "bg-secondary-500 w-full md:h-14",
      },
      secondary: { base: "bg-white", searchBtn: "bg-primary-600 md:h-14" },
    },
  },
  defaultVariants: {
    color: "primary",
  },
});

export function SearchForm({
  color,
  healthInsurances,
  isOpen = false,
  specialties,
  stateAndCities,
  showFilter = false,
  query = null,
}: {
  color?: "primary" | "secondary";
  healthInsurances: ListItem[];
  isOpen?: boolean;
  specialties: ListItem[];
  stateAndCities: States[];
  showFilter?: boolean;
  query: Record<string, string> | null;
}): JSX.Element {
  const navigate = useNavigate();
  const [selectedValueSpec, setSelectedValueSpec] = useState<
    string | undefined
  >(undefined);
  const [selectedValueHS, setSelectedValueHS] = useState<string | undefined>(
    undefined,
  );
  const [selectedValueState, setSelectedValueState] = useState<
    string | undefined
  >(undefined);
  const [selectedValueCity, setSelectedValueCity] = useState<
    string | undefined
  >(undefined);
  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    if (query?.hi) {
      setSelectedValueHS(query.hi);
    }
    if (query?.city) {
      setSelectedValueCity(query.city);
    }
    if (query?.uf) {
      const uf = stateAndCities.filter((state) => state.uf === query.uf)[0]
        ?.name;
      if (uf) {
        setSelectedValueState(uf);
      }
    }
    if (query?.sl) {
      setSelectedValueSpec(query.sl);
    }
  }, [query, stateAndCities]);

  const handleButtonClick = (): void => {
    let uf = undefined;
    if (selectedValueState) {
      uf =
        stateAndCities.filter((state) => state.name === selectedValueState)[0]
          ?.uf || "";
    }

    console.log(
      selectedValueSpec,
      selectedValueHS,
      uf,
      selectedValueCity,
      selectedValueState,
    );

    let selectedValues = [
      selectedValueSpec,
      uf,
      selectedValueCity,
      selectedValueHS,
    ]
      .filter((v) => v !== undefined && v !== "")
      .map((value) => toUrlFilename(value as string))
      .join("/");
    if (selectedValues === "") {
      selectedValues = "clinico-geral";
    }

    navigate(`/${selectedValues}`);
  };

  const css = searchForm({ color });

  const content = (
    <section
      aria-label="Buscador de profissionais de saúde"
      className={css.base()}
    >
      <Wrapper className="flex flex-col gap-4 pb-10 md:flex-row md:items-end">
        <div className="md:w-[29%]">
          <Combobox
            color={color}
            label="Especialidade"
            LabelIcon={StethoscopeIcon}
            listItems={specialties}
            onChange={setSelectedValueSpec}
            value={selectedValueSpec}
          />
        </div>
        <div className="md:w-[29%]">
          <Combobox
            color={color}
            label="Plano de Saúde"
            LabelIcon={BookHeartIcon}
            listItems={healthInsurances}
            onChange={setSelectedValueHS}
            value={selectedValueHS}
          />
        </div>
        <div className="md:w-[32%]">
          <CombinedCombobox
            color={color}
            label="Localização"
            LabelIcon={MapIcon}
            list={stateAndCities}
            firstValue={selectedValueState}
            secondValue={selectedValueCity}
            onChangeFirst={setSelectedValueState}
            onChangeSecond={setSelectedValueCity}
          />
        </div>
        <div className="md:w-[10%]">
          <Button
            className={css.searchBtn()}
            variant="secondary"
            size="default"
            type="button"
            onClick={handleButtonClick}
          >
            <Search className="mr-2 h-5 w-5" /> Buscar
          </Button>
        </div>
      </Wrapper>
    </section>
  );

  const cityUfText =
    showFilter && query
      ? [query.city, query.uf]
          .filter((v) => v)
          .join(", ")
          .trim()
      : "";
  const searchTitle =
    showFilter && query
      ? `Encontre o profissional ${query.sl && query.sltype !== "title" ? "de" : ""} ${query.sl ?? ""} ideal ${query.hi ? `conveniado ao plano ${query.hi}` : ""} ${cityUfText ? `em ${cityUfText}` : ""}`
      : "";

  const filter = showFilter ? (
    <Wrapper>
      <Collapsible.Root
        className="CollapsibleRoot"
        open={open}
        onOpenChange={setOpen}
      >
        <div className="flex flex-col py-6 gap-2">
          <h1 className="text-xl font-semibold text-neutral-900">
            {searchTitle}
          </h1>
          {/* <div className="text-neutral-600 text-sm font-normal">
            Mostrando todos os resultados para
          </div>
          <div className="text-xl font-semibold text-neutral-900">
            Profissionais de saúde conveniados à Allianz Saúde em São Paulo
          </div> */}
          <Collapsible.Trigger asChild>
            <Button variant="defaultLight"> Filtros</Button>
          </Collapsible.Trigger>
        </div>
        <Collapsible.Content className="data-[state=closed]:animate-slide-up data-[state=open]:animate-slide-down">
          {content}
        </Collapsible.Content>
      </Collapsible.Root>
    </Wrapper>
  ) : null;

  return <>{showFilter ? filter : content}</>;
}
