import { remove as removeDiacritics } from "diacritics";
import dataCities from "../data/cities.json";
import dataEspecialidadesJson from "../data/data-especialidades.json";
import dataPlanosJson from "../data/data-planos.json";
import dataHI from "../data/health-insurance.json";
import dataNeighborhoods from "../data/neighborhoods.json";
import dataSpecIds from "../data/specialties-ids.json";
import dataSpec from "../data/specialties.json";
import dataUF from "../data/uf.json";

/**
 * Converts a string to a URL-friendly filename.
 * Returns the input after removing diacritics, making it lowercase and removing "'" and replacing "/" with "-" and replacing " " with "-" and merging multiple "'" into one.
 *
 * @param input - The input string to be converted.
 * @returns The converted string with diacritics removed, converted to lowercase, and with certain characters replaced.
 */
export function toUrlFilename(input: string): string {
  return normalizeString(input).replace(/\s+/g, "-").replace(/-+/g, "-");
}

/**
 * Normalizes a string by removing diacritics, converting to lowercase, and removing "'" and "/".
 *
 * @param input - The input string to be normalized.
 * @returns The normalized string with diacritics removed, converted to lowercase, and with certain characters replaced.
 */
export function normalizeString(input?: string): string {
  if (!input) {
    return "";
  }
  return removeDiacritics(input)
    .toLowerCase()
    .replace(/'/g, "")
    .replace(/\//g, "-")
    .replace(/-+/g, " ")
    .replace(/\s+/g, " ");
}

/**
 * Converts a state abbreviation (uf) to the full state name.
 *
 * Returns the full state name if the input matches a uf code or starts with the
 * first 2 letters of a state name in the data. Returns an empty string if no match found.
 *
 * @param input - The input uf code or partial state name
 * @returns The full state name if a match is found, otherwise an empty string
 */
export function ufToStateName(input: string | undefined): string | undefined {
  if (!input) {
    return input;
  }
  const key = input.toLowerCase();
  return (
    dataUF.find(
      (x) => x.uf === key || (input.length > 2 && x.nome.toLowerCase() === key),
    )?.nome ?? ""
  );
}

export function specialtyToDescription(
  input: string | undefined,
): string | undefined {
  if (!input) {
    return input;
  }
  const key = input.toLowerCase();
  return (
    dataSpec.find((x) => x.name === key || x.name.toLowerCase() === key)
      ?.description ?? ""
  );
}

/**
 * Converts a state full state name to abbreviation (uf).
 *
 * Returns the full state name if the input matches a uf code or starts with the
 * first 2 letters of a state name in the data. Returns an empty string if no match found.
 *
 * @param input - The input uf code or partial state name
 * @returns The full state name if a match is found, otherwise an empty string
 */
export function stateNameToUF(input: string | undefined): string | undefined {
  if (!input) {
    return input;
  }
  const key = input.toLowerCase();
  return (
    dataUF.find((x) => x.nome.toLowerCase() === key || x.uf === key)?.uf ?? ""
  );
}

// TODO: cities.json could be in the desired format to avoid any transformation

function initStatesCityMap(): Record<string, Record<string, number>> {
  const dic: Record<string, Record<string, number>> = {};
  for (const x of dataCities) {
    const UF = normalizeString(x.uf);
    const key = normalizeString(x.name);
    if (!dic[UF]) {
      dic[UF] = {};
    }
    dic[UF][key] = x.id;
  }
  return dic;
}

function initCityMap(): Record<string, number> {
  const dic: Record<string, number> = {};
  for (const x of dataCities) {
    const key = normalizeString(x.name);
    if (!dic[key]) {
      dic[key] = x.id;
    }
  }
  return dic;
}

function initCityIdToNameMap(): Record<number, string> {
  const dic: Record<number, string> = {};
  for (const x of dataCities) {
    const key = x.id;
    if (!dic[key]) {
      dic[key] = x.name;
    }
  }
  return dic;
}

/**
 * Initializes a map of specialties from the dataSpec array, with
 * the specialty name as the key and a boolean value.
 *
 * @returns A record mapping specialty names to boolean values.
 */
function initSpecialtyMap(): Record<
  string,
  { id: number; type?: string } | null | undefined
> {
  const dic: Record<string, { id: number; type?: string } | null | undefined> =
    {};
  for (const x of dataSpecIds) {
    const key = normalizeString(x.name);
    if (!dic[key]) {
      dic[key] = { id: x.id };
    }
  }
  // alias
  for (const x of dataEspecialidadesJson) {
    const aliasKey = normalizeString(x[0]);
    // dataHI has priority
    if (!dic[aliasKey] && x[1]) {
      const key =
        x[1] !== "deletar"
          ? normalizeString(x[1])
          : normalizeString("Clínica médica");
      const sl = dic[key];
      dic[aliasKey] = sl ? { id: sl.id, type: x[3] } : null;
    }
  }
  return dic;
}

function initHealthInsuranceMap(): Record<string, number> {
  const dic: Record<string, number> = {};
  for (const x of dataHI) {
    const key = normalizeString(x.name);
    if (!dic[key]) {
      dic[key] = x.id;
    }
  }
  // alias
  for (const x of dataPlanosJson) {
    const aliasKey = normalizeString(x[0]);
    // dataHI has priority
    if (!dic[aliasKey] && x[1] && x[1] !== "deletar") {
      const key = normalizeString(x[1]);
      dic[aliasKey] = dic[key];
    }
  }
  return dic;
}

function initCitiesNeighborhoodMap(): Record<string, Record<string, number>> {
  const dic: Record<string, Record<string, number>> = {};
  for (const x of dataNeighborhoods) {
    // format: [id, neighborhood name, idCity]
    const id = Number(x[0]);
    const key = normalizeString(String(x[1]));
    const idCity = String(Number(x[2]));
    if (!dic[idCity]) {
      dic[idCity] = {};
    }
    dic[idCity][key] = id;
  }
  return dic;
}

const statesCitiesMap = initStatesCityMap();

const citiesMap = initCityMap();

const cityIdToNameMap = initCityIdToNameMap();

const specialtyMap = initSpecialtyMap();

const healthInsuranceMap = initHealthInsuranceMap();

const cityNeighborhoodMap = initCitiesNeighborhoodMap();

function findAndRemove(
  parts: string[],
  map: Record<string, unknown>,
): string | null {
  for (let i = 0; i < parts.length; i++) {
    const p = normalizeString(parts[i]);
    if (map[p]) {
      parts.splice(i, 1);
      return p;
    }
  }
  return null;
}

function findAndRemoveState(parts: string[]): string | null {
  return findAndRemove(parts, statesCitiesMap);
}

function findAndRemoveCity(
  parts: string[],
  state?: string | null,
): string | null {
  const dic = !state ? citiesMap : statesCitiesMap[state] ?? citiesMap;
  return findAndRemove(parts, dic);
}

function findAndRemoveName(parts: string[]): string | null {
  for (let i = 0; i < parts.length; i++) {
    const p = parts[i].toLowerCase();
    if (p === "nome" && i < parts.length - 1) {
      const name = parts[i + 1];
      parts.splice(i, 2);
      return normalizeString(name);
    }
  }
  return null;
}

function findAndRemoveHealthSpecialty(parts: string[]): string | null {
  return findAndRemove(parts, specialtyMap);
}

function findAndRemoveHealthInsurancePlan(parts: string[]): string | null {
  return findAndRemove(parts, healthInsuranceMap);
}

function findAndRemoveNeighborhood(parts: string[]): string | null {
  const key = parts.pop();
  return key ? normalizeString(key) : null;
}

export interface SearchQuery {
  state: string | null;
  city: string | null;
  cityId: number | null;
  cityFullName: string | null;
  name: string | null;
  specialty: string | null;
  specialtyId: number | null;
  specialtyType: string | null;
  healthInsurance: string | null;
  healthInsuranceId: number | null;
  neighborhood: string | null;
  neighborhoodId: number | null;
  hasError: boolean;
}

/**
 * Extracts search query parts and returns a SearchQuery object.
 *
 * Example of search queries
 * https://www.helpsaude.com/acupunturista/rj/rio-de-janeiro
 * https://www.helpsaude.com/acupunturista/amil/rj/rio-de-janeiro
 * https://www.helpsaude.com/acupunturista/rj/rio-de-janeiro/vila-da-penha -- bairro as last part
 * https://www.helpsaude.com/vila-da-penha/rj/acupunturista/rio-de-janeiro -- example of different part ordering
 * https://www.helpsaude.com/acupunturista/rj/rio-de-janeiro/nome/alda  -- parameter nome
 * https://www.helpsaude.com/acupunturista/rj/rio-de-janeiro/nome/alda/vila-da-penha  -- parameter nome; bairro as last part
 * -- parameter clinica failed to reproduce
 *
 * @param parts - An array of string parts representing the search query.
 * @returns A SearchQuery object containing the extracted parts.
 *
 */
export function extractSearchQueryParts(parts?: string[]): SearchQuery {
  // extract
  const remainingParts = parts?.slice() ?? [];
  const name = findAndRemoveName(remainingParts);
  const state = findAndRemoveState(remainingParts);
  const specialty = findAndRemoveHealthSpecialty(remainingParts);
  const healthInsurance = findAndRemoveHealthInsurancePlan(remainingParts);
  const city = findAndRemoveCity(remainingParts, state);
  const neighborhood =
    state && city ? findAndRemoveNeighborhood(remainingParts) : null;
  const hasError = !parts || remainingParts.length > 0;

  // map name to id
  const cityId = city ? citiesMap[city] ?? null : null;
  const cityFullName = cityId ? cityIdToNameMap[cityId] ?? null : null;
  const sl = specialty ? specialtyMap[specialty] : null;
  const specialtyId = sl?.id ?? null;
  const specialtyType = sl?.type ?? null;
  const healthInsuranceId = healthInsurance
    ? healthInsuranceMap[healthInsurance] ?? null
    : null;
  const neighborhoodId =
    cityId && neighborhood
      ? cityNeighborhoodMap[cityId]?.[neighborhood] ?? null
      : null;

  return {
    state,
    city,
    cityId,
    cityFullName,
    name,
    specialty,
    specialtyId,
    specialtyType,
    healthInsurance,
    healthInsuranceId,
    neighborhood,
    neighborhoodId,
    hasError,
  };
}

type RecordType = "specialties" | "cities" | "states" | "healthInsurance";

/**
 * Generates random record data by selecting random values from the provided
 * data types.
 *
 * For each of the provided record types, it will select a random value and
 * add it to the record being generated. The number of records generated is
 * controlled by the `count` parameter.
 *
 * The record keys will be generated by joining the selected values and
 * capitalizing. The record values will be the url-friendly version of the
 * selected values.
 *
 * @param count - The number of random records to generate.
 * @param types - The types of data to select random values from when
 *                generating each record.
 */
export function generateRandomRecord(
  count: number,
  types: RecordType[],
): Record<string, string> {
  const record: Record<string, string> = {};

  for (let i = 0; i < count; i++) {
    const key: string[] = [];
    const value: string[] = [];
    let selectedState: { nome: string; uf: string } | undefined;
    let randomSpecialty: { name: string } | undefined;
    let randomCity: { id: number; name: string; uf: string } | undefined;
    let randomInsurance: { id: number; name: string } | undefined;

    for (const type of types) {
      switch (type) {
        case "specialties":
          randomSpecialty =
            dataSpecIds[Math.floor(Math.random() * dataSpecIds.length)];
          if (randomSpecialty) {
            key.push(capitalize(randomSpecialty.name));
            value.push(toUrlFilename(randomSpecialty.name));
          }
          break;
        case "states":
          selectedState = dataUF[Math.floor(Math.random() * dataUF.length)];
          if (selectedState) {
            key.push(capitalize(selectedState.nome));
            value.push(toUrlFilename(selectedState.uf));
          }
          break;
        case "cities": {
          const selectedState =
            dataUF[Math.floor(Math.random() * dataUF.length)];
          if (selectedState !== undefined) {
            const filteredCities = dataCities.filter(
              (city) => city.uf === selectedState.uf,
            );
            randomCity =
              filteredCities[Math.floor(Math.random() * filteredCities.length)];
          } else {
            randomCity =
              dataCities[Math.floor(Math.random() * dataCities.length)];
          }
          if (randomCity) {
            key.push(capitalize(randomCity.name));
            value.push(toUrlFilename(randomCity.name));
          }
          break;
        }
        case "healthInsurance":
          randomInsurance = dataHI[Math.floor(Math.random() * dataHI.length)];
          if (randomInsurance) {
            key.push(capitalize(randomInsurance.name));
            value.push(toUrlFilename(randomInsurance.name));
          }
          break;
      }
    }
    record[key.join(" ")] = addSlashes(value);
  }
  return record;
}

/**
 * Converts the given string or array of strings into a path segment
 * with slashes.
 */
function addSlashes(keys: string | string[]): string {
  return Array.isArray(keys) ? `/${keys.join("/")}` : `/${keys}`;
}

/**
 * Capitalizes the input string by lowercasing it, splitting it on dashes/underscores,
 * capitalizing the first letter of each word, and joining the words back together.
 */
export function capitalize(input: string | null): string {
  if (!input) {
    return "";
  }
  const ignoreList = [
    "de",
    "do",
    "da",
    "dos",
    "das",
    "e",
    "em",
    "no",
    "na",
    "nos",
    "nas",
  ];
  return input
    .toLowerCase()
    .split(/[-_\s]+/)
    .map((word) => {
      if (ignoreList.includes(word)) {
        return word;
      }
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}
