import type { MetaDescriptor } from "@remix-run/cloudflare";
import type { HealthEntityWith } from "../interfaces/interfaces";

export function defaultMetaTags(): MetaDescriptor[] {
  const title =
    "HelpSaúde - Agende consulta com médicos, psicólogos, dentistas e mais";
  const description =
    "Marque consulta com médicos, psicólogos, dentistas e mais, dos principais planos de saúde do Brasil.";

  const titleElements = [
    {
      title,
    },
    {
      property: "og:title",
      content: title,
    },
    {
      property: "twitter:title",
      content: title,
    },
  ];
  const descriptionElements = [
    {
      name: "description",
      content: description,
    },
    {
      name: "twitter:description",
      content: description,
    },
    {
      property: "og:description",
      content: description,
    },
  ];
  const imageElements = [
    {
      name: "twitter:image",
      content:
        "https://www.helpsaude.com/content/imgs/common/hs-icon-blue-200x200.png",
    },
    {
      property: "og:image",
      content:
        "https://www.helpsaude.com/content/imgs/common/hs-icon-blue-200x200.png",
    },
    {
      name: "twitter:card",
      content: "summary_large_image",
    },
  ];

  return [
    {
      "script:ld+json": [
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          name: "HelpSaúde",
          url: "https://www.helpsaude.com",
          legalName: "HelpSaúde",
          logo: "https://www.helpsaude.com/content/imgs/common/hs-icon-blue-200x200.png",
          foundingDate: "2009",
          sameAs: [
            "https://www.facebook.com/helpsaude",
            "http://twitter.com/helpsaude",
            "https://www.linkedin.com/company/help-saude/",
          ],
          contactPoint: [
            {
              "@type": "ContactPoint",
              contactType: "sales",
              email: "atendimento@helpsaude.com",
              areaServed: "BR",
              availableLanguage: ["Portuguese"],
            },
          ],
        },
        {
          "@context": "https://schema.org",
          "@type": "WebSite",
          name: "HelpSaúde",
          url: "https://www.helpsaude.com",
          potentialAction: {
            "@type": "SearchAction",
            target: "http://helpsaude.com/{search_term}",
            "query-input": "required name=search_term",
          },
        },
      ],
    },
    ...titleElements,
    ...descriptionElements,
    ...imageElements,
    { name: "twitter:site", content: "@helpsaude" },
    { name: "twitter:creator", content: "@helpsaude" },
    { property: "og:locale", content: "pt_BR" },
    {
      property: "og:type",
      content: "website",
    },
    {
      property: "og:site_name",
      content: "HelpSaúde",
    },
    {
      property: "og:url",
      content: "http://www.helpsaude.com",
    },
    {
      content:
        "marcacao de consulta, marque consulta, agendamento de consulta, agende consulta, agenda de consulta, help saude, help saude, helpsaude, helpsaude, helpsaude.com, helpsaude.com.br, bula, bulas, remedio, remedio, remedios, remedios, medicamento, medicamentos, medico, dentista, ortodontista, clinico, clinica, hospital, laboratorio, exame, consulta, unimed, amil, allianz, golden cross, sul america, maritima, unibanco, bradesco, plano de saude, seguro saude, ortopedista, dermatologista, neurologista, cirurgiao, plastica, endereco, consultorio, medico online, medico online, dentista online",
      name: "keywords",
    },
    {
      tagName: "link",
      rel: "canonical",
      href: "https://www.helpsaude.com/",
    },
  ];
}

/**
 * Generates a JSON-LD breadcrumb list for the given HealthEntity item.
 *
 * The breadcrumb list contains the navigation path from the homepage to the
 * specific HealthEntity item. It includes the homepage, specialty, location,
 * and name of the HealthEntity.
 *
 * @param item - The HealthEntity item to generate the breadcrumb for
 *
 * @returns A JSON-LD BreadcrumbList object
 */
export function generateBreadcrumbJsonLdProfile(
  item: HealthEntityWith,
): object {
  const breadcrumbs = [];

  breadcrumbs.push({
    "@type": "ListItem",
    position: 1,
    name: "HelpSaúde",
    item: "https://www.helpsaude.com/",
  });

  const specialty = item.healthEntitySpecialty[0]?.specialty?.name ?? "";

  if (specialty) {
    breadcrumbs.push({
      "@type": "ListItem",
      position: 2,
      name: specialty,
      item: `https://www.helpsaude.com/${specialty.toLowerCase()}`,
    });
  }

  if (item.healthEntityLocation.length > 0) {
    const location = item.healthEntityLocation[0];
    breadcrumbs.push({
      "@type": "ListItem",
      position: breadcrumbs.length + 1,
      name: location.city?.UF ?? "",
      item: specialty
        ? `https://www.helpsaude.com/${specialty.toLowerCase()}/${location.city?.UF?.toLowerCase()}`
        : `https://www.helpsaude.com/${location.city?.UF?.toLowerCase()}`,
    });
    const cityName = location.city?.name.split(" ").join("-").toLowerCase();
    breadcrumbs.push({
      "@type": "ListItem",
      position: breadcrumbs.length + 1,
      name: location.city?.name ?? "",
      item: specialty
        ? `https://www.helpsaude.com/${specialty.toLowerCase()}/${location.city?.UF?.toLowerCase()}/${cityName}`
        : `https://www.helpsaude.com/${location.city?.UF?.toLowerCase()}/${cityName}`,
    });
  }

  breadcrumbs.push({
    "@type": "ListItem",
    position: breadcrumbs.length + 1,
    name: item.fullName,
    item: `https://www.helpsaude.com/${item.currentUrl}`,
  });

  return {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: breadcrumbs,
  };
}

/**
 * Generates JSON-LD breadcrumb data for a search page pathname.
 *
 * Breadcrumbs are generated from the path parts in the pathname, with each part
 * becoming a breadcrumb item. The position, name and URL are generated from
 * the part and its index in the path.
 *
 * @param pathname - The pathname of the search page
 * @returns JSON-LD breadcrumb data
 */
export function generateBreadcrumbJsonLdSearch(pathname: string): object {
  const breadcrumbs = [];

  breadcrumbs.push({
    "@type": "ListItem",
    position: 1,
    name: "HelpSaúde",
    item: "https://www.helpsaude.com/",
  });
  const pathParts = pathname.split("/").filter((p) => p);

  pathParts.map((part: string, index: number) => {
    const name = part
      .split("-")
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join(" ");

    const itemUrl = `https://www.helpsaude.com/${pathParts.slice(0, index + 1).join("/")}`;
    breadcrumbs.push({
      "@type": "ListItem",
      position: index + 2,
      name,
      item: itemUrl,
    });
    return null;
  });

  return {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: breadcrumbs,
  };
}

/**
 * Generates the title for a health entity profile.
 *
 * Takes a HealthEntityWith object and returns a string containing
 * the health entity's full name, specialties as a comma separated
 * list, city and state.
 *
 * @param item - The HealthEntityWith object
 * @returns A string title for the health entity profile
 */
export function generateTitleProfile(item: HealthEntityWith): string {
  // const specialties = item.healthEntitySpecialty
  //   .map((healthEntitySpecialty) => healthEntitySpecialty.specialty?.name ?? "")
  //   .join(" ,");

  // return `${item.fullName}:${specialties ? ` ${specialties} em` : ""} ${item.city}, ${item.state} - HelpSaúde`;
  return `${item.fullName} - HelpSaúde`;
}
/**
 * Generates keywords for a health entity profile search results.
 *
 * Takes a HealthEntityWith object and returns a string containing common
 * search keywords like phone number, address, map, specialties, health plans,
 * and insurance, plus the entity's specialties joined by commas.
 *
 * @param item - The HealthEntityWith object
 * @returns A string of keywords for the health entity profile
 */
export function generateKeywordsProfile(item: HealthEntityWith): string {
  let specialties = "";
  if (item.healthEntitySpecialty.length > 0) {
    specialties = item.healthEntitySpecialty
      .map(
        (healthEntitySpecialty) => healthEntitySpecialty.specialty?.name ?? "",
      )
      .join(" ,");
  }

  return `telefone, endereço, mapa, especialidade, plano de saúde, seguro saúde, ${specialties}`;
}

/**
 * Generates a meta description for a health entity profile search result.
 *
 * Takes a HealthEntityWith object and returns a string containing information
 * about the health entity's name, specialties, location, and supported health plans.
 *
 * @param item - The HealthEntityWith object
 * @returns A string meta description for the health entity profile
 */
export function generateDescriptionProfile(item: HealthEntityWith): string {
  const specialties = item.healthEntitySpecialty
    .map((healthEntitySpecialty) => healthEntitySpecialty.specialty?.name ?? "")
    .filter(Boolean)
    .join(", ");

  const description = `Marque uma consulta e veja mais informações sobre ${item.fullName}`;

  if (specialties) {
    let locationInfo = "";
    if (item.healthEntityLocation.length >= 0) {
      const locationsByState: Record<string, string[]> =
        item.healthEntityLocation.reduce(
          (
            acc: Record<string, string[]>,
            location: { city?: { UF?: string; name?: string } },
          ) => {
            const uf = location.city?.UF ?? "";
            if (!acc[uf]) {
              acc[uf] = [];
            }
            if (location.city?.name && !acc[uf].includes(location.city.name)) {
              acc[uf].push(location.city.name);
            }
            return acc;
          },
          {},
        );

      locationInfo = Object.entries(locationsByState)
        .map(([uf, cities]) => ` em ${cities.join("/ ")}, ${uf}`)
        .join(" e");
    }
    return `${description}: ${specialties}${locationInfo}`;
  }

  return description;
}

/**
 * Generates metadata descriptors for social sharing of a health entity profile.
 *
 * Returns an array of metadata descriptor objects containing Open Graph tags
 * for sharing a health entity profile on social media. Uses information
 * from the provided HealthEntityWith object to populate the tag values.
 */
export function generateSocialOGProfile(
  item: HealthEntityWith,
): MetaDescriptor[] {
  const title = `${item.fullName} - Consulte avaliações e marque consulta`;
  const specialties = item.healthEntitySpecialty
    .map((healthEntitySpecialty) => healthEntitySpecialty.specialty?.name ?? "")
    .join(", ");
  const state = item.healthEntityLocation[0]?.city?.UF;

  const description = `Marcar uma consulta com ${item.fullName}:${specialties ? ` ${specialties} ` : " "}de ${state} - Consultar avaliações de pacientes, contato, planos aceitos e preço.`;
  const image = item.mainImagePath;
  const canonical = `https://www.helpsaude.com/${item.currentUrl}`;
  return [
    {
      name: "og:title",
      content: title,
    },
    {
      name: "og:description",
      content: description,
    },
    {
      name: "og:type",
      content: "website",
    },
    {
      name: "og:image",
      content: image,
    },
    {
      name: "og:url",
      content: canonical,
    },
    {
      name: "og:site_name",
      content: "Helpsaúde",
    },
    {
      name: "og:locale",
      content: "pt_BR",
    },
  ];
}

/**
 * Generates a JSON-LD profile for sharing a health entity on search engines and other sites.
 *
 * Returns a JSON-LD object containing metadata about the health entity, including name,
 * description, image, URL, job title, address, and medical specialties. Populates values
 * from the provided HealthEntityWith object.
 */
function generateItemJsonLdProfile(item: HealthEntityWith): MetaDescriptor {
  return {
    "@context": "https://schema.org",
    "@type": "Physician",
    name: item.fullName,
    description: item.about,
    image: item.mainImagePath,
    url: `https://www.helpsaude.com${item.currentUrl}`,
    jobTitle: item.fullName,
    address: {
      "@type": "PostalAddress",
      streetAdress: "Rua Paulo Cesar Fidelis , 39 - 1 ANDAR - CEP: 13087-727",
    },
    medicalSpecialty: item.healthEntitySpecialty.map(
      (healthEntitySpecialty) => healthEntitySpecialty.specialty?.name ?? "",
    ),
  };
}

/**
 * Generates metadata descriptors for search engine optimization (SEO) based on search parameters and results.
 *
 * Returns an array of MetaDescriptor objects containing OpenGraph and JSON-LD metadata for search results.
 * Populates values like canonical URL, breadcrumb trail and individual result metadata.
 */
export function generateSearchSEO(params: string): MetaDescriptor[] {
  const title =
    "Encontre Médicos e Profissionais de Saúde | Especialidades, Planos de Saúde e Localizações";
  const keywords =
    "Médicos, Profissionais de Saúde, Especialidades Médicas, Planos de Saúde, Localização, Avaliações, Busca Médica, Melhores Médicos, Médicos Perto de Mim";
  const description =
    "Pesquise entre profissionais de saúde e médicos por especialidades, planos de saúde aceitos, localização e mais. Veja perfis detalhados, avaliações e filtre por sua cidade ou estado.";
  const canonical = `https://www.helpsaude.com${params}`;
  const breadcrumbJsonLd = generateBreadcrumbJsonLdSearch(params);
  // const itemJsonLd = generateItemJsonLdProfile(item);
  // const socialOG = generateSocialOGProfile(item);

  return [
    {
      title,
    },
    {
      name: "description",
      content: description,
    },
    {
      name: "keywords",
      content: keywords,
    },
    {
      tagName: "link",
      rel: "canonical",
      href: canonical,
    },
    {
      "script:ld+json": {
        ...breadcrumbJsonLd,
      },
    },
    // {
    //   "script:ld+json": {
    //     ...itemJsonLd,
    //   },
    // },
    // ...socialOG,
  ];
}

/**
 * Generates metadata descriptors for search engine optimization (SEO) based on a profile item.
 *
 * Returns an array of MetaDescriptor objects containing OpenGraph and JSON-LD metadata for the profile item.
 * Populates values like canonical URL, breadcrumb trail and individual item metadata.
 */
export function generateProfileSEO(item: HealthEntityWith): MetaDescriptor[] {
  const title = generateTitleProfile(item);
  const keywords = generateKeywordsProfile(item);
  const description = generateDescriptionProfile(item);
  const canonical = `https://www.helpsaude.com/${item.currentUrl}`;
  const breadcrumbJsonLd = generateBreadcrumbJsonLdProfile(item);
  const itemJsonLd = generateItemJsonLdProfile(item);
  const socialOG = generateSocialOGProfile(item);

  return [
    {
      title,
    },
    {
      name: "description",
      content: description,
    },
    {
      name: "keywords",
      content: keywords,
    },
    {
      tagName: "link",
      rel: "canonical",
      href: canonical,
    },
    {
      "script:ld+json": {
        ...breadcrumbJsonLd,
      },
    },
    {
      "script:ld+json": {
        ...itemJsonLd,
      },
    },
    ...socialOG,
  ];
}
